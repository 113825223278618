import React, { FC, ComponentProps as CP } from 'react'
import { Heading } from 'rebass'

type Props = Omit<CP<typeof Heading>, 'as' | 'fontSize'>

const PageHeading: FC<Props> = ({ css, ...props }) => (
  <Heading
    as="h1"
    color="text"
    fontSize={['36px', '36px', '36px', '72px']} // TODO hardcoded values
    {...props}
  />
)

export default PageHeading
